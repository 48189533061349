// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ReportingLinesSection = styled.div({
  // Minor left margin to align better with existing icon fields
  marginLeft: "var(--ds-space-050, 4px)",
  marginTop: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ManagerSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: "var(--ds-space-050, 4px)".concat(" ", "var(--ds-space-050, 4px)")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ManagerName = styled.span({
  font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  marginLeft: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var OffsetWrapper = styled.div({
  marginTop: "var(--ds-space-050, 4px)",
  // Offset left margin so the avatar aligns with the heading
  marginLeft: "var(--ds-space-negative-050, -4px)"
});