import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getPageTime } from './performance';

/** Below lines are copied from teams common analytics */
var ANALYTICS_CHANNEL = 'peopleTeams';
var runItLater = function runItLater(cb) {
  var requestIdleCallback = window.requestIdleCallback;
  if (typeof requestIdleCallback === 'function') {
    return requestIdleCallback(cb);
  }
  if (typeof window.requestAnimationFrame === 'function') {
    return window.requestAnimationFrame(cb);
  }
  return function () {
    return setTimeout(cb);
  };
};
export var fireEvent = function fireEvent(createAnalyticsEvent, body) {
  if (!createAnalyticsEvent) {
    return;
  }
  runItLater(function () {
    createAnalyticsEvent(body).fire(ANALYTICS_CHANNEL);
  });
};
/** Above lines are copied from teams common analytics */

var TEAM_SUBJECT = 'teamProfileCard';
var USER_SUBJECT = 'profilecard';
var AGENT_SUBJECT = 'rovoAgentProfilecard';
var createEvent = function createEvent(eventType, action, actionSubject, actionSubjectId) {
  var attributes = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  return {
    eventType: eventType,
    action: action,
    actionSubject: actionSubject,
    actionSubjectId: actionSubjectId,
    attributes: _objectSpread(_objectSpread({
      packageName: "@atlaskit/profilecard",
      packageVersion: "21.1.5"
    }, attributes), {}, {
      firedAt: Math.round(getPageTime())
    })
  };
};
var getActionSubject = function getActionSubject(type) {
  switch (type) {
    case 'user':
      return USER_SUBJECT;
    case 'team':
      return TEAM_SUBJECT;
    case 'agent':
      return AGENT_SUBJECT;
    default:
      return 'user';
  }
};
export var cardTriggered = function cardTriggered(type, method) {
  return createEvent('ui', 'triggered', getActionSubject(type), undefined, {
    method: method
  });
};
export var teamRequestAnalytics = function teamRequestAnalytics(action, attributes) {
  return createEvent('operational', action, TEAM_SUBJECT, 'request', attributes);
};
export var userRequestAnalytics = function userRequestAnalytics(action, attributes) {
  return createEvent('operational', action, USER_SUBJECT, 'request', attributes);
};
export var profileCardRendered = function profileCardRendered(type, actionSubjectId, attributes) {
  return createEvent('ui', 'rendered', getActionSubject(type), actionSubjectId, attributes);
};
export var actionClicked = function actionClicked(type, attributes) {
  return createEvent('ui', 'clicked', getActionSubject(type), 'action', attributes);
};
export var reportingLinesClicked = function reportingLinesClicked(attributes) {
  return createEvent('ui', 'clicked', USER_SUBJECT, 'reportingLines', attributes);
};
export var moreActionsClicked = function moreActionsClicked(type, attributes) {
  return createEvent('ui', 'clicked', getActionSubject(type), 'moreActions', attributes);
};
export var teamAvatarClicked = function teamAvatarClicked(attributes) {
  return createEvent('ui', 'clicked', TEAM_SUBJECT, 'avatar', attributes);
};
export var moreMembersClicked = function moreMembersClicked(attributes) {
  return createEvent('ui', 'clicked', TEAM_SUBJECT, 'moreMembers', attributes);
};
export var errorRetryClicked = function errorRetryClicked(attributes) {
  return createEvent('ui', 'clicked', TEAM_SUBJECT, 'errorRetry', attributes);
};
export var agentRequestAnalytics = function agentRequestAnalytics(action, actionSubjectId, attributes) {
  return createEvent('operational', action, AGENT_SUBJECT, actionSubjectId || 'request', attributes);
};