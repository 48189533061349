import qs from 'qs';

import { Pipeline } from 'src/components/pipelines/models';
import {
  RepositoryDynamicPipelinesParams,
  WorkspaceDynamicPipelinesParams,
} from 'src/components/pipelines/models/DynamicPipelines';
import { WorkspaceThirdPartySecretsParams } from 'src/components/pipelines/models/SecretProvider';
import envBaseUrl from 'src/components/pipelines/utils/env-base-url';

import { ORIGIN, YML_FILE_NAME } from '../constants';

import extractUsername from './extractUsername';
import formatUuid from './formatUuid';

export function getPipelinesPageURL(repoPath: string, route = ''): string {
  return `/${repoPath}/pipelines${route}`;
}

export function getPipelinePageURL(
  pipeline: Pipeline | { uuid?: string; buildNumber?: string | number },
  repoPath: string,
  runUuid?: string
): string {
  const run = runUuid ? `/runs/${formatUuid(runUuid)}/` : '';
  const route = `/results/${
    pipeline.buildNumber || formatUuid(pipeline.uuid)
  }${run}`;

  return `/${repoPath}/pipelines${route}${window.location.search}`;
}
export function getChildPipelineUrl(pipelineUuid: string): string {
  return `/results/${formatUuid(pipelineUuid)}`;
}

export function getParentPipelineUrl(
  pipelineUuid: string,
  repoPath: string
): string {
  return `/${repoPath}/pipelines/results/${formatUuid(pipelineUuid)}`;
}
export function getStepPageURL(
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string,
  repoPath?: string,
  runUuid?: string,
  persistQueryParams?: boolean
): string {
  const pipelineId = pipeline.buildNumber || formatUuid(pipeline.uuid);
  const run = runUuid ? `runs/${formatUuid(runUuid)}/` : '';
  const route = `/results/${pipelineId}/${run}steps/${formatUuid(stepUuid)}`;
  const query = persistQueryParams ? window.location.search : '';

  return `/${repoPath}/pipelines${route}${query}`;
}

export function getTestReportPageURL(
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string,
  repoPath?: string,
  runUuid?: string
): string {
  const stepPage = getStepPageURL(pipeline, stepUuid, repoPath, runUuid);
  return `${stepPage}/test-report`;
}

export function getAccountPageURL(accountUuid: string): string {
  return `${ORIGIN}/${accountUuid}`;
}

export function getProjectPageURL(
  repoPath: string,
  projectKey: string
): string {
  return `${ORIGIN}/account/user/${extractUsername(
    repoPath
  )}/projects/${projectKey}`;
}

export function getSettingsPageURL(repoPath: string, addonKey: string): string {
  return `${ORIGIN}/${repoPath}/admin/${addonKey}/settings`;
}

export function getDeploymentSettingsPageURL(
  repoPath: string,
  addonKey: string
): string {
  return `${ORIGIN}/${repoPath}/admin/addon/admin/${addonKey}/deployment-settings`;
}

export function getAddConfigurationURL(
  repoPath: string,
  branch: string,
  ymlToCopy = ''
): string {
  let yml = ymlToCopy;
  if (yml) {
    yml = `&source=${encodeURIComponent(yml)}&disableFilename=true`;
  }
  return `${ORIGIN}/${repoPath}/create-file/?filename=${YML_FILE_NAME}&at=${branch}${yml}&fromOnboarding=true`;
}

export function getDeploymentsPageURL(
  repoPath: string,
  addonKey: string
): string {
  return `${ORIGIN}/${repoPath}/addon/${addonKey}/deployments`;
}

export function getPlansPageURL(repoPath = ''): string {
  const workspaceSlug = repoPath.split('/')[0];
  return `${ORIGIN}/${workspaceSlug}/workspace/settings/plans-and-billing/`;
}

export function getAdminPlans(workspace?: string): string {
  if (!workspace) {
    return `${ORIGIN}/account/admin/plans/`;
  }
  return `${ORIGIN}/${workspace}/workspace/settings/plans-and-billing/`;
}

export function getTestExecutionUrl(
  testUuid: string,
  fullyQualifiedName: string,
  averageDuration: number
): string {
  return `test-cases/${testUuid}?fullyQualifiedName=${encodeURIComponent(
    fullyQualifiedName
  )}&averageDuration=${averageDuration}`;
}
export const getRepositoryDynamicPipelinesProvidersUrl = (
  params: RepositoryDynamicPipelinesParams
) => {
  const { repositoryOwner, repositorySlug } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryOwner}/${repositorySlug}/pipelines-config/dynamic-pipelines-provider`;
};

export const getThirdPartySecretsProviderUrl = (
  params: WorkspaceThirdPartySecretsParams
) => {
  return `${envBaseUrl('/!api/internal')}/workspaces/${
    params.workspaceSlug
  }/pipelines-config/third-party-secrets-provider`;
};

export function getWorkspaceRunnersPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/pipelines/account-runners`;
}

export function getWorkspaceDynamicPipelinesPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/pipelines/dynamic-pipelines`;
}

export const getWorkspaceDynamicPipelinesProvidersUrl = (
  params: WorkspaceDynamicPipelinesParams
) => {
  return `${envBaseUrl('/!api/internal')}/workspaces/${
    params.workspaceSlug
  }/pipelines-config/dynamic-pipelines-provider`;
};

export function getPipelinesSettingsPageURL(repoPath: string) {
  return `/${repoPath}/admin/pipelines/settings`;
}

export function getAdminPlansPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/plans-and-billing`;
}

export function getWorkspaceVariablesPageUrl(workspace: string) {
  return `/${workspace}/workspace/settings/pipelines/account-variables`;
}

export function getServicePageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string,
  serviceUuid?: string,
  runUuid?: string
): string {
  return `${getStepPageURL(
    { uuid: pipelineUuid },
    stepUuid,
    repoPath,
    runUuid
  )}${serviceUuid ? `/services/${formatUuid(serviceUuid)}` : ''}`;
}

export function getDeploymentsSettingsPageURL(repoPath: string) {
  return `/${repoPath}/admin/pipelines/deployment-settings`;
}

export const getKnownHostsUrl = (
  repoPath: string,
  page?: string | number,
  pagelen?: string | number,
  uuid?: string
): string => {
  const query = qs.stringify({
    page,
    pagelen,
  });
  return `${repoPath}/pipelines_config/ssh/known_hosts${
    uuid ? `/${uuid}` : '/'
  }${query ? `?${query}` : ''}`;
};

export function getArtifactsPageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string,
  runUuid?: string
): string {
  return `${getStepPageURL(
    { uuid: pipelineUuid },
    stepUuid,
    repoPath,
    runUuid
  )}/artifacts`;
}

export function getMetricsPageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string,
  runUuid?: string
): string {
  return `${getStepPageURL(
    { uuid: pipelineUuid },
    stepUuid,
    repoPath,
    runUuid
  )}/metrics`;
}

export function getRecommendationsPageURL(
  repoPath: string,
  pipelineUuid: string,
  stepUuid: string,
  runUuid?: string
): string {
  return `${getStepPageURL(
    { uuid: pipelineUuid },
    stepUuid,
    repoPath,
    runUuid
  )}/recommendations`;
}
