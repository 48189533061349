import FeatureGates from '@atlaskit/feature-gate-js-client';

/**
 * This experiment is being used to _suppress_ the choreographer behavior, for half of new tenants.
 * If this check fails, then it means the customer is either a pre-existing tenant, in which case,
 * we don't want to risk regressing behavior, OR it means you're a new tenant but have fallen into
 * the control bucket, in which case, the Choreographer will behave as expected.
 *
 * If this check _passes_, it means we want to intentionally NOT use the choreographer, so we will
 * force things to behave as they did pre-choreographer, will means that we will block nothing.
 */
export function isChoreographerSuppressed() {
  return FeatureGates.initializeCompleted() && FeatureGates.getExperimentValue('post-office_ipm_choreographer_new_tenants', 'supressChoreographer', false);
}