/**
 * This file was automatically generated by @atlaskit/adf-schema-generator
 * DO NOT MODIFY IT BY HAND. Instead, modify the source files in "packages/adf-schema/src/next-schema" ,
 * and run "yarn workspace @atlaskit/adf-schema build:schema:all" to regenerate this file.
 */

import { createPMNodeSpecFactory } from '../../schema/createPMSpecFactory';
export var blockCard = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    url: {
      default: null
    },
    datasource: {
      default: null
    },
    width: {
      default: null
    },
    layout: {
      default: null
    },
    data: {
      default: null
    }
  },
  selectable: true,
  draggable: true
});
export var blockquote = createPMNodeSpecFactory({
  content: '(paragraph | orderedList | bulletList | unsupportedBlock | codeBlock | mediaSingle | mediaGroup)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: true,
  defining: true
});
export var blockquoteLegacy = createPMNodeSpecFactory({
  content: '(paragraph | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: true,
  defining: true
});
export var blockquoteWithoutNestedCodeblockOrMedia = createPMNodeSpecFactory({
  content: '(paragraph | orderedList | bulletList | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: true,
  defining: true
});
export var bodiedExtension = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock)+',
  marks: 'dataConsumer fragment unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true,
  defining: true,
  isolating: true
});
export var bodiedExtensionWithMarks = createPMNodeSpecFactory({
  marks: 'dataConsumer fragment unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true,
  defining: true,
  isolating: true
});
export var bulletList = createPMNodeSpecFactory({
  content: 'listItem+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: false
});
export var caption = createPMNodeSpecFactory({
  content: '(hardBreak | mention | emoji | date | placeholder | inlineCard | status | text | unsupportedInline)*',
  marks: '_',
  selectable: false,
  isolating: true
});
export var codeBlock = createPMNodeSpecFactory({
  content: '(text | unsupportedInline)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    language: {
      default: null
    },
    uniqueId: {
      default: null
    }
  },
  code: true,
  defining: true
});
export var codeBlockRootOnly = createPMNodeSpecFactory({
  content: '(text | unsupportedInline)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  attrs: {
    language: {
      default: null
    },
    uniqueId: {
      default: null
    }
  },
  code: true,
  defining: true
});
export var confluenceJiraIssue = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  atom: true,
  attrs: {
    issueKey: {
      default: ''
    },
    macroId: {
      default: null
    },
    schemaVersion: {
      default: null
    },
    server: {
      default: null
    },
    serverId: {
      default: null
    }
  }
});
export var confluenceUnsupportedBlock = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    cxhtml: {
      default: null
    }
  }
});
export var confluenceUnsupportedInline = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  atom: true,
  attrs: {
    cxhtml: {
      default: null
    }
  }
});
export var date = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    timestamp: {
      default: ''
    }
  },
  selectable: true
});
export var dateStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    timestamp: {
      default: ''
    }
  },
  selectable: true
});
export var decisionItem = createPMNodeSpecFactory({
  content: 'inline*',
  marks: '_',
  attrs: {
    localId: {
      default: ''
    },
    state: {
      default: 'DECIDED'
    }
  },
  defining: true
});
export var decisionList = createPMNodeSpecFactory({
  content: '(decisionItem | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    localId: {
      default: ''
    }
  },
  selectable: false,
  defining: true
});
export var doc = createPMNodeSpecFactory({
  content: '(block | codeBlock | layoutSection | blockRootOnly | expand)+',
  marks: 'unsupportedMark unsupportedNodeAttribute alignment indentation dataConsumer fragment breakout'
});
export var embedCard = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    url: {
      default: ''
    },
    layout: {
      default: 'center'
    },
    width: {
      default: 100
    },
    originalHeight: {
      default: null
    },
    originalWidth: {
      default: null
    }
  },
  selectable: true
});
export var emoji = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    shortName: {
      default: ''
    },
    id: {
      default: ''
    },
    text: {
      default: ''
    }
  },
  selectable: true
});
export var emojiStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    shortName: {
      default: ''
    },
    id: {
      default: ''
    },
    text: {
      default: ''
    }
  },
  selectable: true
});
export var expand = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock | nestedExpand)+',
  marks: 'unsupportedMark unsupportedNodeAttribute fragment dataConsumer',
  group: 'block',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export var expandRootOnly = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock | nestedExpand)+',
  marks: 'unsupportedMark unsupportedNodeAttribute fragment dataConsumer',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export var expandWithoutNestedExpand = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute fragment dataConsumer',
  group: 'block',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export var extension = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export var extensionWithMarks = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export var extensionFrameStage0 = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | extension | bodiedExtension | unsupportedBlock | blockCard | embedCard)+',
  marks: 'dataConsumer fragment unsupportedMark unsupportedNodeAttribute',
  attrs: {},
  selectable: false,
  definingAsContext: false,
  definingForContent: true,
  isolating: true
});
export var hardBreak = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: '\n'
    }
  },
  selectable: false,
  linebreakReplacement: true
});
export var heading = createPMNodeSpecFactory({
  content: 'inline*',
  marks: 'link em strong strike subsup underline textColor annotation backgroundColor typeAheadQuery confluenceInlineComment unsupportedNodeAttribute unsupportedMark code dataConsumer fragment border',
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export var headingWithAlignment = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export var headingWithIndentation = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export var headingWithNoMarks = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export var image = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    src: {
      default: ''
    },
    alt: {
      default: ''
    },
    title: {
      default: null
    }
  },
  draggable: true
});
export var inlineCard = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    url: {
      default: null
    },
    data: {
      default: null
    }
  },
  selectable: true,
  draggable: true
});
export var inlineCardStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    url: {
      default: null
    },
    data: {
      default: null
    }
  },
  selectable: true,
  draggable: true
});
export var inlineExtension = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export var inlineExtensionWithMarks = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export var layoutColumn = createPMNodeSpecFactory({
  content: '(block | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute alignment indentation dataConsumer fragment',
  attrs: {
    width: {
      default: undefined
    }
  },
  selectable: false,
  isolating: true
});
export var layoutSection = createPMNodeSpecFactory({
  content: '(layoutColumn | unsupportedBlock){1,3} unsupportedBlock*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true
});
export var layoutSectionFull = createPMNodeSpecFactory({
  content: '(layoutColumn | unsupportedBlock){2,3}',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true
});
export var layoutSectionWithSingleColumnStage0 = createPMNodeSpecFactory({
  content: '(layoutColumn | unsupportedBlock){1,5} unsupportedBlock*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  attrs: {
    columnRuleStyle: {
      default: null
    }
  },
  isolating: true
});
export var listItem = createPMNodeSpecFactory({
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock) (paragraph | bulletList | orderedList | taskList | mediaSingle | codeBlock | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  defining: true
});
export var listItemWithNestedDecisionStage0 = createPMNodeSpecFactory({
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock | decisionList) (paragraph | bulletList | orderedList | taskList | mediaSingle | codeBlock | unsupportedBlock | decisionList)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  defining: true
});
export var media = createPMNodeSpecFactory({
  attrs: {
    type: {
      default: 'file'
    },
    id: {
      default: ''
    },
    alt: {
      default: ''
    },
    collection: {
      default: ''
    },
    height: {
      default: null
    },
    occurrenceKey: {
      default: null
    },
    width: {
      default: null
    },
    __contextId: {
      default: null
    },
    __displayType: {
      default: null
    },
    __external: {
      default: false
    },
    __fileMimeType: {
      default: null
    },
    __fileName: {
      default: null
    },
    __fileSize: {
      default: null
    },
    __mediaTraceId: {
      default: null
    },
    url: {
      default: null
    }
  },
  selectable: true
});
export var mediaGroup = createPMNodeSpecFactory({
  content: '(media | unsupportedBlock)+',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {},
  selectable: false
});
export var mediaInline = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    type: {
      default: 'file'
    },
    url: {
      default: null
    },
    id: {
      default: ''
    },
    alt: {
      default: ''
    },
    collection: {
      default: ''
    },
    occurrenceKey: {
      default: null
    },
    width: {
      default: null
    },
    height: {
      default: null
    },
    __fileName: {
      default: null
    },
    __fileSize: {
      default: null
    },
    __fileMimeType: {
      default: null
    },
    __displayType: {
      default: null
    },
    __contextId: {
      default: null
    },
    __mediaTraceId: {
      default: null
    },
    __external: {
      default: false
    }
  },
  selectable: true
});
export var mediaSingle = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    layout: {
      default: 'center'
    },
    width: {
      default: null
    }
  },
  selectable: true,
  content: 'media|unsupportedBlock+|media unsupportedBlock+',
  marks: 'annotation border link unsupportedMark unsupportedNodeAttribute'
});
export var mediaSingleCaption = createPMNodeSpecFactory({
  content: 'media|unsupportedBlock+|media (caption|unsupportedBlock) unsupportedBlock*',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  atom: false,
  attrs: {
    layout: {
      default: 'center'
    },
    width: {
      default: null
    }
  },
  selectable: true
});
export var mediaSingleFull = createPMNodeSpecFactory({
  content: 'media|unsupportedBlock+|media (caption|unsupportedBlock) unsupportedBlock*',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  atom: false,
  attrs: {
    width: {
      default: null
    },
    layout: {
      default: 'center'
    },
    widthType: {
      default: null
    }
  },
  selectable: true
});
export var mediaSingleWidthType = createPMNodeSpecFactory({
  content: 'media|unsupportedBlock+|media unsupportedBlock+',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  atom: true,
  attrs: {
    width: {
      default: null
    },
    layout: {
      default: 'center'
    },
    widthType: {
      default: null
    }
  },
  selectable: true
});
export var mention = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    id: {
      default: ''
    },
    localId: {
      default: null
    },
    text: {
      default: ''
    },
    accessLevel: {
      default: ''
    },
    userType: {
      default: null
    }
  },
  selectable: true
});
export var mentionStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    id: {
      default: ''
    },
    localId: {
      default: null
    },
    text: {
      default: ''
    },
    accessLevel: {
      default: ''
    },
    userType: {
      default: null
    }
  },
  selectable: true
});
export var multiBodiedExtensionStage0 = createPMNodeSpecFactory({
  content: 'extensionFrame+',
  marks: 'unsupportedNodeAttribute unsupportedMark',
  group: 'blockRootOnly',
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true,
  definingAsContext: true
});
export var nestedExpand = createPMNodeSpecFactory({
  content: '(paragraph | heading | mediaSingle | mediaGroup | codeBlock | bulletList | orderedList | taskList | decisionList | rule | panel | blockquote | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export var nestedExpandWithNoMarks = createPMNodeSpecFactory({
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export var orderedList = createPMNodeSpecFactory({
  content: 'listItem+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    order: {
      default: 1
    }
  },
  selectable: false
});
export var panel = createPMNodeSpecFactory({
  content: '(paragraph | heading | bulletList | orderedList | blockCard | mediaGroup | mediaSingle | codeBlock | taskList | rule | decisionList | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    panelType: {
      default: 'info'
    },
    panelIcon: {
      default: null
    },
    panelIconId: {
      default: null
    },
    panelIconText: {
      default: null
    },
    panelColor: {
      default: null
    }
  },
  selectable: true
});
export var panelWithNestedBlockquoteStage0 = createPMNodeSpecFactory({
  content: '(paragraph | heading | bulletList | orderedList | blockCard | mediaGroup | mediaSingle | codeBlock | taskList | rule | decisionList | unsupportedBlock | blockquote)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    panelType: {
      default: 'info'
    },
    panelIcon: {
      default: null
    },
    panelIconId: {
      default: null
    },
    panelIconText: {
      default: null
    },
    panelColor: {
      default: null
    }
  },
  selectable: true
});
export var paragraph = createPMNodeSpecFactory({
  content: 'inline*',
  marks: 'link em strong strike subsup underline textColor annotation backgroundColor typeAheadQuery confluenceInlineComment unsupportedNodeAttribute unsupportedMark code dataConsumer fragment border',
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export var paragraphWithAlignment = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export var paragraphWithIndentation = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export var paragraphWithNoMarks = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export var placeholder = createPMNodeSpecFactory({
  marks: '',
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: ''
    }
  },
  selectable: false
});
export var rule = createPMNodeSpecFactory({
  group: 'block'
});
export var status = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: ''
    },
    color: {
      default: ''
    },
    localId: {
      default: ''
    },
    style: {
      default: ''
    }
  },
  selectable: true
});
export var statusStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: ''
    },
    color: {
      default: ''
    },
    localId: {
      default: ''
    },
    style: {
      default: ''
    }
  },
  selectable: true
});
export var table = createPMNodeSpecFactory({
  content: 'tableRow+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    displayMode: {
      default: null
    },
    isNumberColumnEnabled: {
      default: false
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: ''
    },
    width: {
      default: null
    },
    __autoSize: {
      default: false
    }
  },
  selectable: true,
  isolating: true,
  tableRole: 'table'
});
export var tableWithNestedTable = createPMNodeSpecFactory({
  content: 'tableRow+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    displayMode: {
      default: null
    },
    isNumberColumnEnabled: {
      default: false
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: ''
    },
    width: {
      default: null
    },
    __autoSize: {
      default: false
    }
  },
  selectable: true,
  isolating: true,
  tableRole: 'table'
});
export var tableCell = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaSingle | mediaGroup | decisionList | taskList | blockCard | embedCard | extension | nestedExpand | unsupportedBlock)+',
  marks: 'alignment unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  attrs: {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    },
    background: {
      default: null
    }
  },
  selectable: false,
  isolating: true,
  tableRole: 'cell'
});
export var tableCellWithNestedTable = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaSingle | mediaGroup | decisionList | taskList | blockCard | embedCard | extension | nestedExpand | unsupportedBlock | table)+',
  marks: 'alignment unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  attrs: {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    },
    background: {
      default: null
    }
  },
  selectable: false,
  isolating: true,
  tableRole: 'cell'
});
export var tableHeader = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaSingle | mediaGroup | decisionList | taskList | blockCard | embedCard | extension | nestedExpand)+',
  marks: 'alignment unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  attrs: {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    },
    background: {
      default: null
    }
  },
  selectable: false,
  isolating: true,
  tableRole: 'header_cell'
});
export var tableHeaderWithNestedTable = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaSingle | mediaGroup | decisionList | taskList | blockCard | embedCard | extension | nestedExpand | table)+',
  marks: 'alignment unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  attrs: {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    },
    background: {
      default: null
    }
  },
  selectable: false,
  isolating: true,
  tableRole: 'header_cell'
});
export var tableRow = createPMNodeSpecFactory({
  content: '(tableCell | tableHeader)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  tableRole: 'row'
});
export var tableRowWithNestedTable = createPMNodeSpecFactory({
  content: '(tableCell | tableHeader)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  tableRole: 'row'
});
export var taskItem = createPMNodeSpecFactory({
  content: 'inline*',
  marks: '_',
  attrs: {
    localId: {
      default: ''
    },
    state: {
      default: 'TODO'
    }
  },
  selectable: false,
  defining: true
});
export var taskList = createPMNodeSpecFactory({
  content: '(taskItem | unsupportedBlock)+ (taskItem | taskList | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    localId: {
      default: ''
    }
  },
  selectable: false,
  defining: true
});
export var text = createPMNodeSpecFactory({
  group: 'inline'
});
export var textCodeInline = createPMNodeSpecFactory({
  group: 'inline'
});
export var textFormatted = createPMNodeSpecFactory({
  group: 'inline'
});
export var textWithNoMarks = createPMNodeSpecFactory({});
export var unsupportedBlock = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    originalValue: {
      default: {}
    }
  },
  selectable: true
});
export var unsupportedInline = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    originalValue: {
      default: {}
    }
  },
  selectable: true
});